import { mapActions, mapMutations } from "vuex";
import sharedConfirmDialogTypes from "@/lib/calendesk-js-library/components/confirmDialogs/sharedConfirmDialogTypes";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";
import confirmDialogTypes from "@/components/ConfirmDialogs/confirmDialogTypes";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  mixins: [sharedActions],
  data() {
    return {
      isLoading: false,
      dragging: false,
      categories: [],
      dragData: {
        currentlyDragged: null,
      },
    };
  },
  methods: {
    ...mapMutations({
      setCategory: "category/SET_CATEGORY_ID",
    }),
    ...mapActions({
      removeCategoryAction: "category/delete",
      updateCategory: "category/update",
      updateService: "service/update",
      deleteService: "service/delete",
    }),
    handleRemoveService(el) {
      const { id } = el;
      this.removeService(id);
    },
    removeService(id) {
      this.openConfirmDialog({
        type: sharedConfirmDialogTypes.COMMON,
        confirmAction: () => {
          pushEvent("removeService");
          this.setCommonDialogLoader(true);
          this.deleteService(id)
            .then(() => {
              successNotification("deleted_service_event_correctly");
            })
            .catch((error) => {
              errorNotification("deleting_service_event_failed", error, false);
            })
            .finally(() => {
              this.setCommonDialogLoader(false);
              this.closeConfirmDialog();
            });
        },
      });
    },
    async removeCategory({ id, services }) {
      if (!id && services !== 0) {
        return;
      }
      this.setLoading(true);
      try {
        pushEvent("removeCategory");
        await this.removeCategoryAction(id);
      } catch (error) {
        if (error.response.data.code === "CATEGORY_HAS_SERVICES") {
          errorNotification(
            "services_already_assigned_in_category",
            error,
            false,
            {
              services: "",
            }
          );
        } else {
          errorNotification(null, error, false);
        }
      } finally {
        this.setLoading(false);
        this.$emit("close");
      }
    },
    calculatePosition(where, array) {
      if (this.dragData && this.dragData.currentlyDragged) {
        const draggedId = this.dragData.currentlyDragged.id;
        const relatedCategoryId = this.dragData.currentlyDragged.category_id;
        const index = array.findIndex((x) => x.id === draggedId);
        let previous = array[index - 1] && array[index - 1].order_position;
        let next = array[index + 1] && array[index + 1].order_position;

        if (!previous && !next) {
          previous = 65535;
          next = 65535 * 2;
        } else if (!previous) {
          previous = +next / 2;
        } else if (!next) {
          next = +previous * 2;
        }

        previous = +previous;
        next = +next;
        if (previous && next) {
          array[index].order_position = (+previous + +next) / 2;
        } else if (next) {
          array[index].order_position = +next / 2;
        } else {
          array[index].order_position = +previous + +previous;
        }

        if (where === "services") {
          array[index].category_id = relatedCategoryId;
        }

        this.updatePosition(where, {
          id: draggedId,
          order_position: array[index].order_position,
          category_id: relatedCategoryId,
        });
      }

      this.dragData.currentlyDragged = null;
    },
    updatePosition(where, value) {
      if (where === "categories") {
        this.handleUpdateCategory({
          id: value.id,
          order_position: value.order_position,
        });
      } else if (where === "services") {
        this.handleUpdateService(value);
      }
    },
    checkMove(e) {
      const el = e.draggedContext.element;
      const categoryId = e.relatedContext.element
        ? e.relatedContext.element.category_id
        : e.relatedContext.component.$parent.$parent.category.id;
      this.dragData.currentlyDragged = {
        id: el.id,
        order_position: el.order_position,
        category_id: categoryId,
      };
    },
    onEnd(origin, items) {
      this.dragging = false;
      if (origin === "categories") {
        this.calculatePosition(origin, items);
      } else if (origin === "services") {
        const flatList = items.flat();
        this.calculatePosition(origin, flatList);
      }
    },
    setLoading(bool) {
      if (bool !== undefined) {
        this.isLoading = bool;
        this.$emit("loading", bool);
      }
    },
    async handleUpdateCategory(data) {
      try {
        await this.updateCategory(data);
      } catch (err) {
        errorNotification("error_occurred", err);
      }
    },
    async handleUpdateService(val) {
      try {
        await this.updateService(val);
      } catch (err) {
        errorNotification("error_occurred", err);
      }
    },
    moveService(element, item) {
      if (element && item) {
        this.setLoading(true);
        const obj = {
          id: element.id,
          category_id: item.id,
          name: element.name,
        };

        this.updateService(obj)
          .catch((error) => {
            errorNotification(null, error);
          })
          .finally(() => {
            this.setLoading(false);
          });
      }
    },
    handleEditService({ id }) {
      this.$router.push({ name: "service", params: { id } });
    },
    handleDuplicateService({ id }) {
      this.$router.push({ name: "add_service", params: { duplicateId: id } });
    },
    handleEditCategory(el) {
      this.openConfirmDialog({
        type: confirmDialogTypes.EDIT_CATEGORY,
        data: el,
      });
    },
    handleRemoveCategory(el) {
      const { id } = el;
      const services = el.services ? el.services.length : 0;
      this.openConfirmDialog({
        type: confirmDialogTypes.REMOVE_CATEGORY,
        data: { id, services },
      });
    },
    handleAddNewService(category) {
      if (category) {
        this.setCategory(category.id);
      }
      this.$router.push({ name: "add_service" });
    },
    handleAddNewCategory() {
      this.openConfirmDialog({
        type: confirmDialogTypes.ADD_CATEGORY,
      });
    },
  },
};

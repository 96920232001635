<template>
  <v-container v-if="getSelectedService" fluid>
    <v-row>
      <v-col cols="12" md="6" class="d-flex justify-start align-center">
        <app-bar-return />
        <div>
          <new-app-bar-route
            :title="$helpers.getServiceName(getSelectedService, null, true)"
            :subtitle="$trans('section_admin_panel_offer')"
          />
          <div>
            <v-chip
              small
              :color="$helpers.getStatusColor(getSelectedService.status).color"
              :class="$helpers.getStatusColor(getSelectedService.status).class"
            >
              <v-icon left>$check</v-icon>
              {{
                isServiceActive
                  ? $trans("service_active")
                  : $trans("service_blocked")
              }}
            </v-chip>
          </div>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="d-flex justify-start justify-md-end align-center flex-wrap"
      >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NewAppBarRoute from "@/views/dashboard/components/AppBar/AppBarComponents/NewAppBarRoute.vue";
import statsActions from "@/calendesk/mixins/statsActions";
import categoryServiceActions from "@/calendesk/mixins/categoryServiceActions";
import { mapGetters } from "vuex";
import AppBarReturn from "@/views/dashboard/components/AppBar/AppBarComponents/AppBarReturn.vue";

export default {
  components: {
    AppBarReturn,
    NewAppBarRoute,
  },
  mixins: [statsActions, categoryServiceActions],
  computed: {
    ...mapGetters({
      getSelectedService: "service/getSelectedService",
    }),
    isServiceActive() {
      return (
        this.getSelectedService &&
        this.getSelectedService.status === this.$helpers.serviceStatus.ACTIVE
      );
    },
  },
};
</script>
